import { useContext, useEffect, useState } from 'react'
import Screen from '../../components/screen/screen.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../components/server/server.component'
import { useHistory } from 'react-router'

import styles from './blog-edit.module.css'
import ReactMarkdown from 'react-markdown'

const BlogEditScreen = () => {
  const { blogs } = useContext(Server)
  const history = useHistory()

  const [items, setItems] = useState()
  const [date, setDate] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [editId, setEditId] = useState()
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    blogs.loadPage(0, 25, setItems, console.error)
  }, [blogs])

  const save = () => {
    if (editId) {
      blogs.update(editId, { date, title, description }, () => blogs.loadPage(0, 25, setItems, console.error), console.error)
    } else {
      blogs.create({ date, title, description }, () => blogs.loadPage(0, 25, setItems, console.error), console.error)
    }
  }

  return (
    <>
      <div className={styles.header}>
        <div onClick={() => history.replace('/')} style={{ cursor: 'pointer' }}>
          Garten
        </div>
        <div>Blog bearbeiten</div>
      </div>
      <Screen className={styles.panels}>
        <div>
          <div onClick={() => {}}>
            <FontAwesomeIcon icon={faPlus} />
          </div>

          {items &&
            items.map(item => (
              <div
                onClick={() => {
                  setEditId(item.id)
                  setDate(`${item.date[0]}-${item.date[1]}-${item.date[2]}`)
                  setTitle(item.title)
                  setDescription(item.description)
                }}
              >
                {item.date[0]}-{item.date[1]}-{item.date[2]} - {item.title}
              </div>
            ))}
        </div>
        <div>
          <div>
            <input placeholder="Datum" value={date} onChange={({ target: { value } }) => setDate(value)} />
          </div>
          <div>
            <input placeholder="Titel" value={title} onChange={({ target: { value } }) => setTitle(value)} />
          </div>
          <div>
            {preview ? <ReactMarkdown>{description}</ReactMarkdown> : <textarea placeholder="Beschreibung" value={description} onChange={({ target: { value } }) => setDescription(value)} />}
            <button onClick={() => setPreview(false)}>Bearbeiten</button>
            <button onClick={() => setPreview(true)}>Vorschau</button>
          </div>
          <button onClick={save}>Speichern</button>
        </div>
      </Screen>
    </>
  )
}

export default BlogEditScreen
