import { useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import Restrict from '../../components/restrict/restrict.component'
import { Server } from '../../components/server/server.component'
import { auth } from '../../firebase/firebase.utils'

import styles from './home.module.css'

var authStateHandler = null

const HomeScreen = () => {
  const history = useHistory()
  const { blogs } = useContext(Server)
  const [currentUser, setCurrentUser] = useState()
  const [roles, setRoles] = useState([])
  const [items, setItems] = useState()

  useEffect(() => {
    authStateHandler = auth.onAuthStateChanged(user => {
      console.log('auth state changed', user)
      setCurrentUser(user)
    })
    return () => {
      authStateHandler()
    }
  })

  useEffect(() => {
    if (currentUser) {
      console.log('lookup roles', currentUser)
      currentUser.getIdTokenResult().then(result => {
        console.log('got roles', result.claims.roles)
        setRoles(result.claims.roles)
      })
    }
  }, [currentUser])

  useEffect(() => {
    console.log('useEffect', 'loadPage', blogs)
    blogs.loadPage(0, 25, setItems, console.error)
  }, [blogs, currentUser])

  console.log('render', items)

  const a = currentUser ? (
    <>
      <Restrict allowed={['user', 'admin']} roles={roles}>
        <Link to="/photos">
          <span className={styles.menu}>Fotos</span>
        </Link>
        <Link to="/plantedit">
          <span className={styles.menu}>Pflanzen bearbeiten</span>
        </Link>
        <Link to="/blogedit">
          <span className={styles.menu}>Blog bearbeiten</span>
        </Link>
      </Restrict>
    </>
  ) : null

  return (
    <div className={styles.home}>
      <h1>Der Garten der Familie Beckers</h1>
      <div className={styles.menu}>
        <span onClick={() => history.push('/plants')}>Pflanzen</span>
        {a}
      </div>
      <div className={styles.container}>
        <iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=3&p=48&l=ez&f=ifr&linkID=ad5216161798c21e5e1f725821ad2b08&t=abeckers-21&tracking_id=abeckers-21" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{ border: 'none' }} frameBorder="0"></iframe>
        {items &&
          items.map(item => (
            <div className={styles.blog}>
              <h2>
                {item.date[2]}.{item.date[1]}.{item.date[0]} - {item.title}
              </h2>
              <ReactMarkdown>{item.description}</ReactMarkdown>
            </div>
          ))}
      </div>
    </div>
  )
}

export default HomeScreen
