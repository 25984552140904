import { useContext, useEffect, useState } from 'react'
import { Server } from '../../components/server/server.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Screen from '../../components/screen/screen.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { useHistory } from 'react-router'

import styles from './plant-edit.module.css'
import ReactMarkdown from 'react-markdown'

const PlantEditScreen = () => {
  const { plants } = useContext(Server)

  const history = useHistory()

  const [items, setItems] = useState()
  const [allItems, setAllItems] = useState()
  const [group, setGroup] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [editId, setEditId] = useState()
  const [preview, setPreview] = useState(false)
  const [filter, setFilter] = useState('')

  useEffect(() => {
    plants.load(setAllItems, console.error)
  }, [plants])

  useEffect(() => {
    if (allItems) {
      const x = allItems.filter(item => item.group?.toLowerCase().includes(filter.toLowerCase()) || item.name?.toLowerCase().includes(filter.toLowerCase()))
      x.sort((a, b) => {
        const gdiff = compareString(a.group, b.group)
        return gdiff === 0 ? compareString(a.group, b.group) : gdiff
      })
      setItems(x)
    }
  }, [allItems, filter])

  const save = () => {
    if (editId) {
      plants.update(editId, { group, name, description }, () => plants.load(setAllItems, console.error), console.error)
    } else {
      plants.create({ group, name, description }, () => plants.load(setAllItems, console.error), console.error)
    }
  }

  return (
    <>
      <div className={styles.header}>
        <div onClick={() => history.replace('/')} style={{ cursor: 'pointer' }}>
          Garten
        </div>
        <div>Pflanzen bearbeiten</div>
      </div>

      <Screen className={styles.panels}>
        <div>
          <div
            onClick={() => {
              setEditId(null)
              setGroup('')
              setName('')
              setDescription('')
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <input value={filter} onChange={({ target: { value } }) => setFilter(value)} placeholder="Filter" />

          {items &&
            items.map(item => (
              <div
                onClick={() => {
                  setEditId(item.id)
                  setGroup(item.group)
                  setName(item.name)
                  setDescription(item.description)
                }}
              >
                {item.group} - {item.name}
              </div>
            ))}
        </div>
        <div>
          <div>
            <input placeholder="Gruppe" value={group} onChange={({ target: { value } }) => setGroup(value)} />
          </div>
          <div>
            <input placeholder="Name" value={name} onChange={({ target: { value } }) => setName(value)} />
          </div>
          <div>
            {preview ? <ReactMarkdown>{description}</ReactMarkdown> : <textarea placeholder="Beschreibung" value={description} onChange={({ target: { value } }) => setDescription(value)} />}
            <button onClick={() => setPreview(false)}>Bearbeiten</button>
            <button onClick={() => setPreview(true)}>Vorschau</button>
          </div>
          <button onClick={save}>Speichern</button>
        </div>
      </Screen>
    </>
  )
}

export default PlantEditScreen

function compareString(a, b) {
  const au = a ? a.toLowerCase() : ''
  const bu = b ? b.toLowerCase() : ''
  if (au < bu) return -1
  if (au > bu) return 1
  return 0
}
