import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { auth } from '../../firebase/firebase.utils';
import ScreenTitle from '../screen-title/screen-title.component';

var authStateHandler = null;
var initializing = true;

const CheckAccess = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [roles, setRoles] = useState([]);
    const history = useHistory();

    const gotoSignIn = () => {
        history.replace("/signin");
    }

    useEffect(() => {
        authStateHandler = auth.onAuthStateChanged(user => {
            initializing = false;
            console.log("authState changed", user)
            setCurrentUser(user);
        });
        return authStateHandler;
    });

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then(result => {
                setRoles(result.claims.roles);
            });
        }
    }, [currentUser])

    if (initializing) {
        return null;
    }

    if (currentUser && roles) {
        return children;
    }

    if (!currentUser) {
        return (
            <div>
                <ScreenTitle label="Sie sind nicht angemeldet. Melden Sie sich an, um die Funktionen dieser Webseite nutzen zu können." />
                <button onClick={gotoSignIn}>Anmelden</button>
            </div>
        )
    }

    return (
        <div>
            <ScreenTitle label="Sie sind noch nicht freigeschaltet. Wenden Sie sich an den Administrator." />
        </div>
    )
}

export default CheckAccess;
