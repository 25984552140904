import { useContext } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Server } from '../server/server.component'

const DraggablePhoto = ({ photo, onChange }) => {
  const { photos, plants } = useContext(Server)

  const [{ opacity }, dragRef] = useDrag({
    type: 'Photo',
    item: photo,
    collect: monitor => ({ opacity: monitor.isDragging() ? 0.5 : 1 }),
  })

  const addPlantToPhoto = plant => {
    plants.linkPhoto(plant.id, photo.id, onChange, console.error)
  }

  const [{ isOver }, drop] = useDrop({
    accept: 'Plant',
    drop: addPlantToPhoto,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return (
    <div ref={drop}>
      <div ref={dragRef} style={{ opacity, position: 'relative', backgroundColor: isOver ? '#ddd' : null }}>
        <img src={photos.src(photo.id)} /> {photo.date[2]}.{photo.date[1]}.{photo.date[0]} - {photo.caption}
        <div> {photo.plantIds && photo.plantIds.map(plant => <div>{plant}</div>)}</div>
      </div>
    </div>
  )
}

export default DraggablePhoto
