import SignInWithGoogle from '../../components/signin-with-google/signin-with-google.component'

const SigninScreen = () => {
    return (
        <div>
            <SignInWithGoogle />
        </div>
    )
}

export default SigninScreen
