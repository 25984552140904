import { useContext } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Server } from '../server/server.component'

const DraggablePlant = ({ plant, onChange }) => {
  const { photos, plants } = useContext(Server)

  const [{ opacity }, dragRef] = useDrag({
    type: 'Plant',
    item: plant,
    collect: monitor => ({ opacity: monitor.isDragging() ? 0.5 : 1 }),
  })

  const addPhotoToPlant = photo => {
    plants.linkPhoto(plant.id, photo.id, onChange, console.error)
  }

  const [{ isOver }, drop] = useDrop({
    accept: 'Photo',
    drop: plant => addPhotoToPlant(plant),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return (
    <div ref={drop}>
      <div ref={dragRef} style={{ opacity, position: 'relative', backgroundColor: isOver ? '#ddd' : null }}>
        <div>
          {plant.group} - {plant.name}
        </div>
        {plant.pictureIds && plant.pictureIds.map(pic => <img src={photos.src(pic)} />)}
      </div>
    </div>
  )
}

export default DraggablePlant
