import React, { useState } from 'react';

import styles from './drag-drop.module.css';

var dragCounter = 0;

const DragDrop = ({ onDrop, children }) => {
    const [drag, setDrag] = useState(false);
    console.log("hasFile?");

    const hasFile = (transfer) => {
        if (transfer.items) {
            for (var i = 0; i < transfer.items.length; i++) {
                if (transfer.items[i].kind === 'file') {
                    return true;
                }
            }
        }
        return false;
    }

    const onDragOver = (e) => {
        e.preventDefault()
        e.stopPropagation()
    };
    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter++;
        if (hasFile(e.dataTransfer)) {
            setDrag(true);
        }
    };

    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--;
        if (dragCounter === 0) {
            setDrag(false)
        }
    };

    const handleDrop = (e) => {
        console.log("handleDrop");
        e.preventDefault()
        e.stopPropagation()
        setDrag(false);
        if (hasFile(e.dataTransfer)) {
            console.log("hasFile!");
            onDrop(e.dataTransfer.files);
            dragCounter = 0;
        }
    };

    return (
        <div className={styles.dragDrop + " " + (drag ? styles.dragging : "")}
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDragOver={onDragOver}
            onDrop={handleDrop}
        >
            {children}
        </div>
    )
}

export default DragDrop;
