import { useContext, useEffect, useState } from "react";
import DragDrop from "../../components/drag-drop/drag-drop.component"
import Screen from "../../components/screen/screen.component";
import { Server } from "../../components/server/server.component";
import DraggablePhoto from "../../components/draggable-photo/draggable-photo.component";
import DraggablePlant from "../../components/draggable-plant/draggable-plant.component";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHistory } from "react-router";

import styles from './photo-upload.module.css'

const PhotoUploadScreen = () => {
    const history = useHistory();
    const { photos, plants } = useContext(Server);
    const [date, setDate] = useState("");
    const [caption, setCaption] = useState("")

    const [photoItems, setPhotoItems] = useState()
    const [photoFilter, setPhotoFilter] = useState('')
    const [filteredPhotos, setFilteredPhotos] = useState([])

    const [plantItems, setPlantItems] = useState()
    const [plantFilter, setPlantFilter] = useState('')
    const [filteredPlants, setFilteredPlants] = useState([])

    const upload = newFiles => {
        const data = new FormData();
        for (var i = 0; i < newFiles.length; i++) {
            console.log(newFiles[i]);
            if (newFiles[i].name) {
                data.append('files', newFiles[i]);
                data.append('lastModified', newFiles[i].lastModified);
                data.append('date', date);
                data.append('caption', caption);
            }
        }
        photos.upload(data, () => photos.load(setPhotoItems, console.error), console.error)
    }

    useEffect(() => {
        photos.load(setPhotoItems, console.error)
        plants.load(setPlantItems, console.error)

    }, [photos, plants])

    useEffect(() => {
        if (plantItems) {
            setFilteredPlants(plantItems.filter(item => {
                return item.name.toLowerCase().includes(plantFilter) || item.group.toLowerCase().includes(plantFilter)
            }))
        }
    }, [plantItems, plantFilter])

    useEffect(() => {
        if (photoItems) {
            setFilteredPhotos(photoItems.filter(item => {
                let d = `${item.date[2]}.${item.date[1]}.${item.date[0]}`
                return item.caption.toLowerCase().includes(photoFilter) || d.includes(photoFilter)
            }))
        }
    }, [photoItems, photoFilter])

    return (<>
        <div className={styles.header}>
            <div onClick={() => history.replace('/')} style={{ cursor: 'pointer' }}>Garten</div>
            <div>Fotos hochladen</div>
        </div>
        <Screen>
            <div className={styles.inputs}>
                <input placeholder="Datum der Aufnahme" value={date} onChange={({ target: { value } }) => setDate(value)} />
                <input placeholder="Bildunterschrift" value={caption} onChange={({ target: { value } }) => setCaption(value)} />
            </div>
            <DragDrop onDrop={upload}>
                <div className={styles.dropZone}>Fotos hier fallenlassen!</div>
            </DragDrop>

            <DndProvider backend={HTML5Backend}>
                <div className={styles.assign}>
                    <div className={styles.photos}>
                        <h4>Fotos <input placeholder="Filtern" onChange={({ target: { value } }) => setPhotoFilter(value.toLowerCase())} /></h4>
                        {filteredPhotos.map(item => <DraggablePhoto onChange={() => {
                            photos.load(setPhotoItems, console.error)
                            plants.load(setPlantItems, console.error)
                        }} photo={item} />)}
                    </div>
                    <div className={styles.plants}>
                        <h4>Pflanzen <input placeholder="Filtern" onChange={({ target: { value } }) => setPlantFilter(value.toLowerCase())} /></h4>
                        {filteredPlants.map(item => <DraggablePlant onChange={() => {
                            photos.load(setPhotoItems, console.error)
                            plants.load(setPlantItems, console.error)
                        }} plant={item} />)}
                    </div>
                </div>
            </DndProvider>
        </Screen>
    </>
    )
}

export default PhotoUploadScreen
