import React from 'react'
import { signInWithGoogle } from '../../firebase/firebase.utils';
import { useHistory } from "react-router-dom";

import styles from './signin-with-google.module.css';

const SignInWithGoogle = () => {
    const history = useHistory();
    return (
        <button onClick={ () => signInWithGoogle().then(result => history.replace("/")) }>
            Mit Google anmelden
        </button>
    )
}

export default SignInWithGoogle
