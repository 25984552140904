import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyBZ9FIVHqqdiuZxs5El9YAWB7SHFoleOvA",
  authDomain: "garten-f0d42.firebaseapp.com",
  projectId: "garten-f0d42",
  storageBucket: "garten-f0d42.appspot.com",
  messagingSenderId: "769878646874",
  appId: "1:769878646874:web:62ae69c35ec3339f9ab863"
};

firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
