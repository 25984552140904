import React, { useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useHistory } from 'react-router'
import gfm from 'remark-gfm'
import { Server } from '../../components/server/server.component'

import styles from './plants.module.css'

const _groups = [
  {
    name: 'Birnenbäume',
    text: `14.04-2021 - Neben einem Solitärbaum haben wir auch einige Säulenbäume, doch die Früchte vom
Solitärbaum schmecken am besten.

![1](/images/birne1/Birne1_20210418_1.jpg)`,
    plants: [
      {
        name: 'Birne 1',
        text: `22.04.2021 - Eine prachtvolle Blüte. Wird es dieses Jahr viele Birnen geben?

![2](/images/birne1/birne1_20210422_1.jpg)
![3](/images/birne1/birne1_20210422_2.jpg)
![4](/images/birne1/birne1_20210422_3.jpg)

14.04.2021 - Dies ist unsere Solitärbirne. Er wächst ein wenig schräg und durch Holunder
in der Nähe ist er immer krank, aber seine Birnen sind dennoch lecker.

![1](/images/birne1/Birne1_20210418_1.jpg)`,
      },
      {
        name: 'Birne 2',
        text: `22.04.2021 - Ein wenig Frost scheint diese Säulenbirne abbekommen zu haben.
Trotzdem ist wohl genug Nektar für dieses Insekt Nektar zu finden.

![1](/images/birne2/Birne2_20210422_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Buchsbaum',
    plants: [
      {
        name: 'Buchsbaum 1',
        text: `14.04.2021 - Dieser kleine Buchsbaum am Zaun zu den Nachbarn ist über 20 Jahre alt.
Gepflegt haben wir ihn kaum. Gelegentlioch wurde er geschnitten. Da er sich aber gerne hinter einer Goldrute
versteckt, wird er gerne vergessen. Der Buchsbaumzünsler war noch bei ihm.

![1](/images/buchsbaum1/Buchsbaum1_20210418_1.jpg)`,
      },
    ],
  },
  {
    name: 'Chrysantheme',
    plants: [
      {
        name: 'Chrysantheme',
        text: `14.04.2021 - Chrysantemen gibt es in vielen Farben. Ich habe mich für eine
weiße entschieden.

![1](/images/chrysantheme/Chrysantheme_20210418_1.jpg)

|||
|--------|------|
|Standort|sonnig, halbschatten|
|Blütezeit|Juni - August|
|Höhe|45 cm|
`,
      },
    ],
  },
  {
    name: 'Fetthenne',
    plants: [
      {
        name: 'Fetthenne 1',
        text: `14.04.2021 - Diese Fetthenne ist schon seit sehr vielen Jahren in diesem Garten.
Sie blüht mit kleinen rosa Blüten.

![1](/images/fetthenne1/Fetthenne1_20210418_1.jpg)`,
      },
    ],
  },
  {
    name: 'Glockenblume',
    plants: [
      {
        name: 'Glockenblume',
        text: `14.04.2021 - Neu in diesem Jahr ist diese kleine Glockenblume.
                
![1](/images/glockenblume/Glockenblume_20210418_1.jpg)`,
      },
    ],
  },
  {
    name: 'Heidelbeere',
    plants: [
      {
        name: 'Heidelbeere 1',
        text: `22.04.2021 - Ein Teil der Blüten ist beretis geöffnet und lädt
diesen Brummer zu einem Imbiss ein.

![2](/images/heidelbeere1/Heidelbeere1_20210422_1.jpg)
![3](/images/heidelbeere1/Heidelbeere1_20210422_2.jpg)
                
14.04.2021 - Diese Heidelbeerpflanze wurde auch schon vor vielen Jahren
gepflanzt. Damals habe ich ein großes Loch gebuddelt und mit Rhododendronerde aufgefüllt.
Seitdem wächst dieser Strauch sehr gut und bringt sehr viele leckere Früchte.

![1](/images/heidelbeere1/Heidelbeere1_20210418_1.jpg)`,
      },
    ],
  },
  {
    name: 'Hibiskus',
    plants: [
      {
        name: 'Hibiskus 1',
        text: `14.04.2021 - Dieser Hibiskus ist dreifarbig.
                
![1](/images/hibiskus1/Hibiskus1_20210418_1.jpg)`,
      },
      {
        name: 'Hibiskus 2',
        text: `14.04.2021 - Welche Farbe hat der noch gleich? Da müssen wir wohl auf die Blüten warten...
                
![1](/images/hibiskus2/Hibiskus2_20210418_1.jpg)`,
      },
    ],
  },
  {
    name: 'Himalaja-Schleierkraut',
    text: `14.04.2021 - Diese Pflanzen habe unter dem späten Frost stark gelitten. Ob sie im
nächsten Jahr nochmal kommen?

![1](/images/himalajaschleierkraut1/Himalaja-Schleierkraut1_20210418_1.jpg)
![2](/images/himalajaschleierkraut2/Himalaja-Schleierkraut2_20210418_1.jpg)`,
    plants: [
      {
        name: 'Himalaja-Schleierkraut 1',
        images: ['/images/himalajaschleierkraut1/Himalaja-Schleierkraut1_20210418_1.jpg'],
      },
      {
        name: 'Himalaja-Schleierkraut 2',
        images: ['/images/himalajaschleierkraut2/Himalaja-Schleierkraut2_20210418_1.jpg'],
      },
    ],
  },
  {
    name: 'Jasmin',
    plants: [
      {
        name: 'Jasmin',
        text: `14.04.2021 - Diesen Duftjasmin habe ich mir damals für meine Dufthecke gekauft.
Die anderen Pflanzen, die dazu gehörten, sind schon lange verschwunden.

![1](/images/jasmin/Jasmin_20210418_1.jpg)`,
      },
    ],
  },
  {
    name: 'Kische',
    plants: [
      {
        name: 'Kirsche 1',
        text: `22.04.2021 - Das ist ein Kischbaum im Miniformat. Er trägt kleine saure Kirchen.

![2](/images/kirsche1/Kirsche1_20210422_1.jpg)
![3](/images/kirsche1/Kirsche1_20210422_2.jpg)
![4](/images/kirsche1/Kirsche1_20210422_3.jpg)

18.04.2021 - Diese Kirsche blüht schon.

![1](/images/kirsche1/Kirsche1_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Lavendel',
    text: `14.04.2021 - Früher hatten wir mehr Lavendelpflanzen. Meine Mutter hatte diese damals
nicht nur wegen ihres Dufts gekauft, sondern auch, weil sie sich von ihnen versprach, dass sie ihre
Rosen beschützen.

![1](/images/lavendel1/Lavendel1_20210418_1.jpg)
![2](/images/lavendel2/Lavendel2_20210418_1.jpg)`,
    plants: [
      {
        name: 'Lavendel 1',
        comment: 'Bei der Kletterrose',
        images: ['/images/lavendel1/Lavendel1_20210418_1.jpg', '/images/lavendel1/Lavendel1_20210418_2.jpg'],
      },
      {
        name: 'Lavendel 2',
        comment: 'Bei der Rose',
        images: ['/images/lavendel2/Lavendel2_20210418_1.jpg'],
      },
    ],
  },
  {
    name: 'Liebstöckel',
    plants: [
      {
        name: 'Liebstöckel',
        text: `18.04.2021 - Während der Bauarbeiten wurde der Liebstöckel
zu großen Teilen verschüttet. Totzdem zeigen sich auch dieses Jahr wieder erste Triebe.

![1](/images/liebstöckel/Liebstöckel_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Lupinen',
    text: `14.04.2021 - Lupinen sind immer wieder schön.

Nach der ersten Blühphase soll man sie zurückschneiden, um eine Nachblüte zu erhalten.
        
![1](/images/lupine1/Lupine1_20210418_1.jpg)
![2](/images/lupine2/Lupine2_20210418_1.jpg)

|||
|--------|------|
|Standort|sonnig, auf frischem Boden|
|Bewässerung|mäßig gießen, Staunässe vermeiden|
|Blütezeit|Juni - August|
|Höhe|50 - 80 cm|
|Düngen|im März und Juli mit Gartendünger düngen|
|Winterhart|Ja|
`,
    plants: [
      {
        name: 'Lupine 1',
        text: `14.04.2021 - rosa`,
        images: ['/images/lupine1/Lupine1_20210418_1.jpg'],
      },
      {
        name: 'Lupine 2',
        text: `14.04.2021 - gelb`,
        images: ['/images/lupine2/Lupine2_20210418_1.jpg'],
      },
    ],
  },
  {
    name: 'Mädchenauge',
    plants: [
      {
        name: 'Mädchenauge',
        text: `14.04.2021 - Diesem kleinen Pflänzchen sieht man nicht an, dass es bis
zu 75 cm hoch werden soll.

![1](/images/mädchenauge/Mädchenauge_20210418_1.jpg)

|||
|--------|------|
|Standort|sonnig, halbschatten|
|Blütezeit|Juni - September|
|Höhe|75 cm|
`,
      },
    ],
  },
  {
    name: 'Margeriten',
    plants: [
      {
        name: 'Margerite',
        text: `14.04.2021 - Leider sind alle Blüten beim letzten Frost erfroren.

![1](/images/margerite1/margerite1_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Pampasgras',
    plants: [
      {
        name: 'Amerikanisches Pampasgras',
        text: `23.04.2021 - Im Internet habe ich gefunden, dass das Pampasgras
erst im Frühjahr (März bis Mai) geschnitten werden soll, wenn die Tage frostfrei sind. Also haben wir
es bisher zu früh geschnitten.

14.04.2021 - Dieses Pampasgras war schon 1999 an dieser Stelle. Wie pflegt
man ihn richtig?

![1](/images/pampasgras/pampasgras_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Pfingstrosen',
    text: `14.04.2021 - Überall im Garten sind Pfingstrosen zu finden.
        
Düngen: Es wird empfohlen, einen organischen Langzeitdünger zu verwenden.
Bei mineralischem Dünger kann es leicht zur Überdüngung kommen.
Wenn der Austrieb beginnt wird zum erstenmal gedüngt, im Juli oder August zum letzten
Mal. Mit mineralischem Dünger alle 4 Wochen.`,
    plants: [
      {
        name: 'Pfingstrose 1',
        comment: 'Ecke Terrasse/Weg',
        text: `14.04.2021 - Zwischen all den anderen Pflanzen (um nicht Unkräutern zu sagen)
ist diese Pfingstrose kaum zu sehen. Hier muss unbedingt mal sauber gemacht werden.

![1](/images/pfingstrose1/Pfingstrose_1_20210418_1.jpg)`,
      },
    ],
  },
  {
    name: 'Prachtspiere',
    text: ``,
    plants: [
      {
        name: 'Prachtspiere 1 (Astilbe chinensis, Pumila)',
        text: `14.04.2021 - An diesem Standort ist es recht schattig. Damit soll
diese Prachtspiere klar kommen.
                
![1](/images/prachtspiere1/Prachtspiere1_20210418_1.jpg)

|||
|--------|------|
|Standort|halbschatten, schatten|
|Blütezeit|Juli - September|
|Höhe|25 cm|
`,
      },
      {
        name: 'Prachtspiere 2 (Astilbe arendsii, Astary Rose Shades)',
        text: `14.04.2021 - Diese Prachtspiere soll eswa größer werden und etwas
früher blühen als die andere. Mal sehen...
                
![1](/images/prachtspiere2/Prachtspiere2_20210418_1.jpg)

|||
|--------|------|
|Standort|halbschatten, schatten|
|Blütezeit|Juni - August|
|Höhe|30 cm|
`,
      },
    ],
  },
  {
    name: 'Rhabarber',
    text: `14.04.2021 - Auch der Rhabarber gehört zu den Pflanzen, die schon im
Garten waren, als wir ihn 1999 gekauft haben. Lange Zeit war er von Bauschutt so eingeengt, 
dass er sich kaum entfalten konnte. Trotzdem hat er diese Zeit überstanden und zeigt uns
sein frisches Grün und Rot.

![1](/images/rhabarber1/Rhabarber1_20210418_1.jpg)
![2](/images/rhabarber2/Rhabarber2_20210418_1.jpg)
`,
    plants: [
      {
        name: 'Rhabarber 1',
        images: ['/images/rhabarber1/Rhabarber1_20210418_1.jpg'],
      },
      {
        name: 'Rhabarber 2',
        images: ['/images/rhabarber2/Rhabarber2_20210418_1.jpg'],
      },
    ],
  },
  {
    name: 'Rhododenren',
    plants: [
      {
        name: 'Rhododendron 1',
        text: `14.04.2021 - Dies ist der Rhododendron, der als erstes blüht. Dieses
Jahr ist ihm das zum Verhängnis geworden. Der späte Frost hat einen großen Teil der Blüten
geschädigt.

![1](/images/rhododendron1/Rhododendron1_20210418_1.jpg)
![2](/images/rhododendron1/Rhododendron1_20210418_2.jpg)
![2](/images/rhododendron1/Rhododendron1_20210418_3.jpg)
`,
      },
      {
        name: 'Rhododendron 2',
        text: `22.04.2021 - Es wird noch ein Weilchen dauern, bis dieser Rhododendron erblühen wird.
                
![1](/images/rhododendron2/Rhododendron2_20210422_1.jpg)
                
14.04.2021 - Dieser Rhododendron hat das dichteste grün. Ich freue mich
schon auf seine lila Blüten.

![1](/images/rhododendron2/Rhododendron2_20210418_1.jpg)
`,
      },
      {
        name: 'Rhododendron 3',
        text: `14.04.2021 - Mit dunklen Blüten wird uns dieser Rhododendron dieses Jahr
hoffentlich wieder erfreuen

![1](/images/rhododendron3/Rhododendron3_20210418_1.jpg)
`,
      },
      {
        name: 'Rhododendron 4',
        text: `14.04.2021 - So eingeklemmt hinter dem Baumaterial. Wenn ich mich recht erinnere
blüht er in rot.

![1](/images/rhododendron4/Rhododendron4_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Riemenblüte',
    plants: [
      {
        name: 'Riemenblüte "Fire Dance"',
        text: `14.04.2021 - Auch dieser Zierstrauch, der früh blüht, hatte mit dem späten Frost
zu kämpfen. Hoffentlich haben wir nächstes Jahr mehr freude an den Blüten.

![1](/images/riemenblüte/Riemenblüte_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Rosen',
    text: `14.04.2021 - Auch die Rosen haben stark darunter gelitten, dass wir uns in den letzten
Jahren nicht ausreichend um sie gekümmert haben. Mal sehen, ob wir sie wieder aufgepeppelt bekommen.

![1](/images/rose1/Rose1_20210418_1.jpg)
`,
    plants: [
      {
        name: 'Rose 1',
        text: `14.04.2021 - Eigentlich eine schöne Rose, aber ich glaube, ihr fehlt ein wenig Luft.
                
![1](/images/rose1/Rose1_20210418_1.jpg)
`,
      },
      {
        name: 'Rose 2',
      },
      {
        name: 'Rose 3',
        text: `14.04.2021 - Zwischen den Vergissmeinnicht und dem Unkraut ist diese Rose kaum
zu erkennen.
                
![1](/images/rose3/Rose3_20210418_1.jpg)
`,
      },
      {
        name: 'Rose 4',
        text: `14.04.2021 - Einge gelbe Rose.
                
![1](/images/rose4/Rose4_20210418_1.jpg)
`,
      },
      {
        name: 'Rose 5',
        text: `14.04.2021 - Und die andere gelbe Rose.
                
![1](/images/rose5/Rose5_20210418_1.jpg)
`,
      },
      {
        name: 'Rose 6',
        text: `14.04.2021 - Diese Rose ist der Zwilling von Rose 3. Da hat es aber eine gut
gemeint mit unerstüzenden Geräten.
                
![1](/images/rose6/Rose6_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Schmetterlingsflieder',
    plants: [
      {
        name: 'Schmetterlingsflieder',
        text: `14.04.2021 - Vor ein paar Jahren wuchsen die Schmetterlingsflieder
fast überall im Garten. Aktuell gibt es jedoch nur noch diesen einen.
                
![1](/images/schmetterlingsflieder/Schmetterlingsflieder_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Schneeglöckchen',
    plants: [
      {
        name: 'Schneeglöckchen',
        text: `14.04.2021 - Diese Schneeglöckchen sind dieses Jahr von ganz hinten
in der Wiese an Rand des Grundstück in das Beet bei den Schuppen umgezogen. Mit einer
prachtvollen Blüte haben sie bestätigt, dass der Umzug ihnen gut bekommen ist. Leider
sind sie (natürlich) schon verblüt bevor ich mit diesem Tagebuch angefangen habe.

![1](/images/schneeglöckchen/Schneeglöckchen_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Schwertlilien',
    plants: [
      {
        name: 'Schwertlilie 1',
        text: `14.04.2021 - Diese Schwertlilien wuchsen neben dem Komposter. Erst vor
kurzen sind sie in das Beet beim Schuppen umgezogen.

![1](/images/schwertlilie1/schwertlilie1_20210418_1.jpg)
`,
      },
      {
        name: 'Schwertlilie 2',
        text: `14.04.2021 - Diese Schwertlilien waren früher nur im Teich. Dann sind
sie auf das Land ausgewandert.

![1](/images/schwertlilie2/Schwertlilie2_20210418_1.jpg)
`,
      },
      {
        name: 'Schwertlilie 3',
        text: `14.04.2021 - Sind das die lachsroten oder die blauen Schwertlilien? Warten wir es ab.

![1](/images/schwertlilie3/Schwertlilie3_20210418_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Sumpfdotterblume',
    plants: [
      {
        name: 'Sumpfdotterblume',
        text: `14.04.2021 - Der Teich ist klein und es gib nicht viele verschiedene
Pflanzen, aber die Sumpfdotterblume ist auf jeden Fall die schönste.

![1](/images/sumpfdotterblume/Sumpfdotterblume_20210418_1.jpg)
![2](/images/sumpfdotterblume/Sumpfdotterblume_20210418_2.jpg)
![3](/images/sumpfdotterblume/Sumpfdotterblume_20210418_3.jpg)
`,
      },
    ],
  },
  {
    name: 'Traubenhyazinthen',
    plants: [
      {
        name: 'Traubenhyazinthen',
        text: `22.04.2021 - Die Traubenhyazinthen sind in unsren Garten eingewandert.
Sie vermehren sich prächtig und das nicht immer wo ich es möchte.

![1](/images/traubenhyazinthen/traubenhyazinthen_20210422_1.jpg)
`,
      },
    ],
  },
  {
    name: 'Tulpen',
    plants: [
      {
        name: 'Tulpen 1',
        text: `22.04.2021 - Eine Woche später strahlen diese Blüten im Garten.

![2](/images/tulpe1/tulpe1_20210422_1.jpg)
![3](/images/tulpe1/tulpe1_20210422_2.jpg)

14.04.2021 - Nicht mehr lange, dann werden diese Tulpen in voller Blüte stehen.

![1](/images/tulpe1/tulpe1_20210418_1.jpg)
`,
      },
      {
        name: 'Tulpen 2',
        text: `22.04.2021 - Leider noch keine Zeit gehabt, hier sauber zu machen.
Aber leuchten sie nicht schön?

![2](/images/tulpe2/tulpe2_20210422_1.jpg)
![3](/images/tulpe2/tulpe2_20210422_2.jpg)

14.04.2021 - Etwas mager. Hier fehlt wohl etwas Pflege. Aber diese Tulpen haben eine sehr
schöne Farbe.

![1](/images/tulpe2/tulpe2_20210418_1.jpg)
`,
      },
      {
        name: 'Tulpen 3',
        text: `22.04.2021 - Am Zaun bei den Kletterrosen.

![2](/images/tulpe3/tulpe3_20210422_1.jpg)

14.04.2021 - Eine weitere Tulpe.

![1](/images/tulpe3/tulpe3_20210418_1.jpg)
`,
      },
    ],
  },
]

const PlantsScreen = () => {
  const { plants, photos } = useContext(Server)
  const history = useHistory()
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedPlant, setSelectedPlant] = useState()
  const [groups, setGroups] = useState()
  const [items, setItems] = useState()

  useEffect(() => {
    plants.load(setItems, console.error)
  }, [plants])

  useEffect(() => {
    if (items) {
      const g = {}
      items.forEach(item => {
        var n = g[item.group]
        if (!n) {
          n = []
          g[item.group] = n
        }
        n.push(item)
      })
      const keys = Object.keys(g)
      keys.sort()
      const x = []
      keys.forEach(key => {
        x.push({
          name: key,
          plants: g[key],
        })
      })
      setGroups(x)
    }
  }, [items])

  if (!groups) {
    return null
  }

  return (
    <>
      <div className={styles.header}>
        <div onClick={() => history.replace('/')} style={{ cursor: 'pointer' }}>
          Garten
        </div>
        <div>Pflanzen</div>
      </div>
      <iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=3&p=48&l=ez&f=ifr&linkID=ad5216161798c21e5e1f725821ad2b08&t=abeckers-21&tracking_id=abeckers-21" width="728" height="90" scrolling="no" border="0" marginWidth="0" style={{ border: 'none' }} frameBorder="0"></iframe>
      <div>
        <div className={styles.body}>
          <div className={styles.menu}>
            {groups.map(g => (
              <span>
                <div
                  className={selectedGroup === g ? styles.selected : styles.group}
                  onClick={() => {
                    setSelectedPlant(null)
                    setSelectedGroup(g)
                  }}
                >
                  {g.name}
                </div>
                {selectedGroup &&
                  selectedGroup.name === g.name &&
                  g.plants.map(p => (
                    <span className={selectedPlant === p ? styles.selected : styles.plant} onClick={() => setSelectedPlant(p)}>
                      {p.name}
                    </span>
                  ))}
              </span>
            ))}
          </div>
          <div className={styles.desc}>
            {!selectedGroup && !selectedPlant && <p>18.04.2001 - Leider haben einige der Pflanzen durch den späten Frost einige Schäden erlitten.</p>}
            {selectedGroup && !selectedPlant && (
              <>
                <div>{selectedGroup.name}</div>
                <ReactMarkdown remarkPlugins={[gfm]}>{selectedGroup.text}</ReactMarkdown>
                {selectedGroup.images &&
                  selectedGroup.images.map(img => (
                    <div>
                      <img src={img} />
                    </div>
                  ))}
              </>
            )}
            {selectedPlant && (
              <>
                <h2>{selectedPlant.name}</h2>
                <ReactMarkdown remarkPlugins={[gfm]}>{selectedPlant.description}</ReactMarkdown>
              </>
            )}
            {selectedPlant && selectedPlant.pictureIds && (
              <div>
                <h2>Galerie</h2>
                {selectedPlant.pictureIds.map(img => (
                  <div>
                    <img src={photos.src(img)} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PlantsScreen
