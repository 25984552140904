import { createContext } from 'react'
import { auth } from '../../firebase/firebase.utils'
import { trackPromise } from 'react-promise-tracker'
import { notify } from 'react-notify-toast'

export const Server = createContext()

export const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://garten.beckers.cf/api'

var lastToken = null

function executeGetAuth(url, query, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    executeFetch(_url, requestOptions, callback, errorCallback)
  })
}

function executePostAuth(url, query, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    console.log('executePost ' + url, body)
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    }
    if (body) {
      myHeaders.append('Content-Type', 'application/json')
      requestOptions.body = JSON.stringify(body)
    }
    console.log('executePost ' + url, requestOptions.body)
    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    executeFetch(_url, requestOptions, callback, errorCallback)
  })
}

function executePutAuth(url, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    }
    executeFetch(baseURL + url, requestOptions, callback, errorCallback)
  })
}

function executePostFormAuth(url, form, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: form,
    }

    executeFetch(baseURL + url, requestOptions, callback, errorCallback)
  })
}

function executeGet(url, query, callback, errorCallback) {
  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  const _url = new URL(baseURL + url)
  if (query) {
    _url.search = new URLSearchParams(query)
  }
  executeFetch(_url, requestOptions, callback, errorCallback)
}

function executeFetch(url, requestOptions, callback, errorCallback) {
  trackPromise(
    fetch(url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (error.toString().includes('NetworkError') || error.message.includes('Failed to fetch')) {
          notify.show('Der Server ist nicht erreichbar. Probieren Sie es später erneut.', 'error', 10000)
        } else if (errorCallback) {
          errorCallback(error)
        }
      })
  )
}

function executeQuery(type, query, callback, errorCallback) {
  if (query) executeGet('/' + type, { q: query }, callback, errorCallback)
  else executeGet('/' + type, null, callback, errorCallback)
}

const ServerProvider = ({ children }) => {
  return (
    <Server.Provider
      value={{
        blogs: {
          loadPage: (page, size, callback, errorCallback) => executeGetAuth('/blogs', { page, size }, callback, errorCallback),
          create: (body, callback, errorCallback) => executePostAuth('/blogs', null, body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => executePutAuth(`/blogs/${id}`, body, callback, errorCallback),
        },
        photos: {
          load: (callback, errorCallback) => executeQuery('photos', null, callback, errorCallback),
          upload: (form, callback, errorCallback) => executePostFormAuth('/photos', form, callback, errorCallback),
          src: id => baseURL + `/photos/${id}/data`,
          update: (id, body, callback, errorCallback) => executePutAuth(`/photos/${id}`, body, callback, errorCallback),
        },
        plants: {
          load: (callback, errorCallback) => executeQuery('plants', null, callback, errorCallback),
          create: (body, callback, errorCallback) => executePostAuth('/plants', null, body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => executePutAuth(`/plants/${id}`, body, callback, errorCallback),
          linkPhoto: (id, photoId, callback, errorCallback) => executePostAuth(`/plants/${id}/photo/${photoId}`, null, null, callback, errorCallback),
        },
      }}
    >
      {children}
    </Server.Provider>
  )
}

export default ServerProvider
