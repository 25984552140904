import { HashRouter as Router, Switch, Route } from "react-router-dom";

import ServerProvider from "./components/server/server.component";
import Notifications from 'react-notify-toast';

import HomeScreen from './screens/home/home.screen';
import SigninScreen from "./screens/signin/signin.screen";
import PlantsScreen from './screens/plants/plants.screen';
import PhotoUploadScreen from "./screens/photo-upload/photo-upload.screen";
import PlantEditScreen from "./screens/plant-edit/plant-edit.screen";
import WaitDialog from "./components/wait-dialog/wait-dialog.component";
import BlogEditScreen from "./screens/blog-edit/blog-edit.screen";

import './App.css';

function App() {
  return (
    <div className="App">
      <ServerProvider>
        <Router>
          <Notifications options={{ zIndex: 10000, top: '50px' }} />
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route exact path="/signin" component={SigninScreen} />
            <Route exact path="/plants" component={PlantsScreen} />
            <Route exact path="/plantedit" component={PlantEditScreen} />
            <Route exact path="/photos" component={PhotoUploadScreen} />
            <Route exact path="/blogedit" component={BlogEditScreen} />
          </Switch>
        </Router>
      </ServerProvider>
      <WaitDialog />
    </div>
  );
}

export default App;
